<template>
  <v-row>
    <DataSourceCard v-for="(dataSource, index) in localDataSources" :key="dataSource.name" class="mt-5 ml-3"
                    :disabled="dataSource.disabled" :clickable="dataSource.clickable"
                    :image="dataSource.image" :value.sync="localDataSources[index].value"
                    :name="dataSource.name"></DataSourceCard>
  </v-row>
</template>

<script>
import DataSourceCard from "@/modules/projects/components/DataSourceCard";

export default {
  name: "DataSourceSelector",
  components: {DataSourceCard},
  data() {
    return {
      localDataSources:[]
    }
  },
  props:{
    dataSourcesData:Array,
    selectedDataSources:Array
  },
  created() {
    this.localDataSources = this.dataSourcesData
  },
  watch: {
    localDataSources : {
      deep:true,
      handler() {
        let names = this.localDataSources.filter(d => d.value).map(t => t.name)
        this.$emit('update:selectedDataSources', names)
      }
    }
  }

}
</script>

<style scoped>

</style>