<template>
  <div class="ma-10">
    <v-row>
      <v-col>
        <h2 class="openSans-400 fz-24" v-text="$vuetify.lang.t('$vuetify.projects.labels.newProject')"></h2>
        <h4 class="text-subtitle-1 my-1 font-weight-thin" v-text="$vuetify.lang.t('$vuetify.projects.labels.intelligentLiteratureMonitoring')"></h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="currentStep == 1">
        <v-form v-model="forms.stepOne">
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="openSans-300 fz-18 txt-secondary mb-6" v-text="$vuetify.lang.t('$vuetify.common.labels.project')"></h3>
              <v-text-field v-model="project.name" class="fz-14" :rules="[rules.required]"
                            :label="$vuetify.lang.t('$vuetify.projects.labels.form.name')" outlined></v-text-field>
              <v-textarea v-model="project.description" class="mt-6 fz-14"
                          :label="$vuetify.lang.t('$vuetify.projects.labels.form.description')" outlined></v-textarea>

              <h3 class="openSans-300 fz-18 txt-secondary mb-6"
                   v-text="$vuetify.lang.t('$vuetify.projects.labels.banner.areasOfInterest')"></h3>
              <v-text-field hide-details="auto" v-model="keywords" class="mt-1" outlined
                            append-outer-icon="mdi-help-circle-outline" @focusout="addKeywords"
                            :label="$vuetify.lang.t('$vuetify.projects.labels.form.keywordsArgs')">
              </v-text-field>
              <v-row class="mt-2 mb-6">
                <v-chip
                  v-for="chip in chips"
                  :key="chip.name"
                  class="ma-2"
                  close
                  outlined
                  color="primary"
                  @click="removeKeyword(chip.name)"
                >
                  {{chip.name}}
                </v-chip>
              </v-row>
            </v-col>
          </v-row>

          <h3 class="openSans-300 fz-18 txt-secondary mb-6" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.dataSources')"></h3>
          <DataSourceSelector :selected-data-sources.sync="project.dataSources" :data-sources-data="dataSources"></DataSourceSelector>
        </v-form>

        <div class="mt-10" align="end">
          <v-btn filled class="px-6" color="primary" medium :disabled="!forms.stepOne"
                 @click="next">{{ $vuetify.lang.t('$vuetify.common.buttons.next') }}</v-btn>
        </div>
      </v-col>
      <v-col v-if="currentStep == 2">
        <h3 class="openSans-300 fz-18 txt-secondary mb-6" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.articlePublicationDate')"></h3>
        <v-spacer></v-spacer>

        <v-form v-model="forms.stepTwo">
          <v-row>
            <v-col cols="12" lg="3" class="pb-0">
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
                      :return-value.sync="project.fromDate" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field hide-details="auto" v-model="project.fromDate" label="From" outlined hint="" :rules="[rules.required]"
                                append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="project.fromDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu1.save(project.fromDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="3" class="pb-0">
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                      :return-value.sync="project.toDate" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="project.toDate" label="To" outlined hint="" :disabled="noEndDate"
                                append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="project.toDate" no-title scrollable :disabled="noEndDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(project.toDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                  v-model="noEndDate" class="mt-0" label="No end date"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3 class="openSans-300 fz-18 txt-secondary mb-6"
                   v-text="$vuetify.lang.t('$vuetify.projects.labels.form.deliverySchedule')"></h3>
              <v-radio-group v-model="project.deliveryFrequency" row>
                <v-radio :label="$vuetify.lang.t('$vuetify.projects.labels.form.daily')" value="DAILY"></v-radio>
                <v-radio :label="$vuetify.lang.t('$vuetify.projects.labels.form.weekly')" value="WEEKLY"></v-radio>
                <v-radio :label="$vuetify.lang.t('$vuetify.projects.labels.form.monthly')" value="MONTHLY"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <v-alert color="blue" type="info" dense outlined prominent text>
                {{ $vuetify.lang.t('$vuetify.projects.labels.messages.notificationEmail') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
        <div class="mt-10" align="end">
          <v-btn class="mx-3 px-6 txt-viridian sorcero-secondary-button" depressed medium @click="previousStep">{{ $vuetify.lang.t('$vuetify.common.buttons.previousStep') }}</v-btn>
          <v-btn color="primary" medium class="px-6" :disabled="!forms.stepTwo"
                 @click="next">{{ $vuetify.lang.t('$vuetify.common.buttons.next') }}</v-btn>
        </div>
      </v-col>
      <v-col v-if="currentStep == 3">
        <div class="mb-6">
          <h3 class="openSans-300 fz-18 txt-secondary mb-6" v-text="$vuetify.lang.t('$vuetify.projects.labels.banner.summary')"></h3>
          <h4 class="openSans-300 fz-16 mb-4" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.name')"></h4>
          <span class="openSans-400 fz-14">{{ project.name }}</span>
        </div>
        <div class="mb-6">
          <h3 class="openSans-300 fz-16 mb-4" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.description')"></h3>
          <span class="openSans-400 fz-14">{{ project.description }}</span>
        </div>
        <div class="mb-6">
          <h3 class="openSans-300 fz-16 mb-4" v-text="$vuetify.lang.t('$vuetify.projects.labels.banner.areasOfInterest')"></h3>
          <v-chip
            v-for="chip in chips"
            :key="chip"
            class="ma-2"
            outlined
            color="primary"
          >
            {{chip.name}}
          </v-chip>
          <div class="openSans-400 fz-14 mt-4 mb-10" v-text="$vuetify.lang.t('$vuetify.projects.labels.messages.areasOfInterestDisclaimer')"></div>
        </div>
        <div class="mb-6">
          <h3 class="openSans-300 fz-16 mb-4" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.dataSources')"></h3>
          <DataSourceCard v-for="dataSource in selectedDataSources"
                          :key="dataSource.name" :clickable="false" :name="dataSource.name"
                          :image="dataSource.image" :disabled="false" :value="true"></DataSourceCard>
        </div>
        <div class="mb-6">
          <h3 class="openSans-300 fz-16 mb-4" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.articlePublicationDate')"></h3>
          <v-row>
            <v-col>
              <h4 class="openSans-400 fz-14">{{ project.fromDate}}</h4>
            </v-col>
            <v-col>
              <h4 class="openSans-400 fz-14">{{ project.toDate }}</h4>
            </v-col>
          </v-row>
        </div>
        <div class="mb-6">
          <h3 class="openSans-300 fz-16 mb-4"
               v-text="$vuetify.lang.t('$vuetify.projects.labels.form.deliverySchedule')"></h3>
          <span class="openSans-400 fz-14">{{ project.deliveryFrequency}}</span>
        </div>
        <div class="mb-6">
          <h3 class="openSans-300 fz-16 mb-4" v-text="$vuetify.lang.t('$vuetify.projects.labels.form.additionalInformation')"></h3>
          <span class="openSans-400 fz-14" v-text="$vuetify.lang.t('$vuetify.projects.labels.messages.additionalInformation')"></span><a href="mailto:sales@sorcero.com"> sales@sorcero.com</a>.<br>

        </div>
        <v-alert v-if="error" color="red" dense dismissible outlined prominent text
                 type="error">{{error}}</v-alert>
        <div class="mt-10" align="end">
          <v-btn class="mx-3 px-6 txt-viridian sorcero-secondary-button" depressed medium @click="previousStep">{{ $vuetify.lang.t('$vuetify.common.buttons.previousStep') }}</v-btn>
          <v-btn color="primary" medium class="px-6" @click="saveProject">{{ $vuetify.lang.t('$vuetify.common.buttons.finish') }}</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataSourceSelector from "@/modules/projects/components/DataSourceSelector";
import image from '@/assets/images/logo_pubmed.svg'
import DataSourceCard from "@/modules/projects/components/DataSourceCard";
import ProjectService from "@/modules/projects/services/ProjectService";

export default {

  name: 'NewProjectForm',
  
  components: {DataSourceCard, DataSourceSelector},
  props: {
    currentStep: Number,
    next: Function,
    previousStep: Function
  },
  data () {
    return {
      keywords: null,
      project: {
        name: '',
        description: '',
        contentType: null,
        keywords: [],
        fromDate: null,
        toDate: null,
        deliveryFrequency:'DAILY',
        dataSources:[]
      },
      forms: {
        stepOne:false,
        stepTwo:false
      },
      noEndDate:false,
      menu1: null,
      menu2: null,
      dataSources: [
          {
            name: "PubMed",
            value: false,
            image: image,
            disabled:false,
            clickable:true
          },
          {
            name: "BioRxiv",
            value: false,
            disabled:true,
            clickable:true
          },
      ],
      error:null,
      rules: {
        required: value => !!value || 'Required.',
      }
    }
  },
  computed: {
    chips() {
      return this.project.keywords.map(k => {return {name:k, available:true}})
    },
    selectedDataSources: {
      get() {
        return this.dataSources.filter(d => this.project.dataSources.includes(d.name))
      },
      set() {
        return this.dataSources.filter(d => this.project.dataSources.includes(d.name))
      }
    }
  },
  methods: {
    addKeywords() {
      if (this.keywords) {
        this.project.keywords.push(...(this.keywords.split(";")).filter(e => e.trim().length > 0))
      }
      this.keywords = null
    },
    removeKeyword(keyword){
      console.log(keyword)
      this.project.keywords = this.project.keywords.filter(e => e != keyword)
    },
    saveProject() {
      let data = {
        project_name: this.project.name,
        project_requirements: this.project,
        account_id: ""
      }
      ProjectService.create(data).then(
          this.backToProjects()
      ).catch(
          e => {
            console.log(e)
            this.error = e
          }
      )
    },
    backToProjects() {
      this.$router.push('/projects')
    },
    parseDate(value) {
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.sorcero-secondary-button {
  background-color: #fff !important; 
}

</style>