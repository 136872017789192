<template>
<div>
  <v-stepper
    v-model="currentStep"
    vertical
    v-if="!$vuetify.breakpoint.xs"
    class="stepper"
  >
    <v-stepper-step
      :complete="currentStep > 1"
      step="1"
      class="step-color"
    >
    {{ $vuetify.lang.t('$vuetify.projects.labels.banner.content') }}
    </v-stepper-step>
    <v-stepper-content v-if="!$vuetify.breakpoint.xs" step="1"></v-stepper-content>
    <v-divider v-if="$vuetify.breakpoint.xs"></v-divider>

    <v-stepper-step
      :complete="currentStep > 2"
      step="2"
      class="step-color"
    >
    {{ $vuetify.lang.t('$vuetify.projects.labels.banner.preferences') }}
    </v-stepper-step>
    <v-stepper-content v-if="!$vuetify.breakpoint.xs"  step="2"></v-stepper-content>
    <v-divider v-if="$vuetify.breakpoint.xs"></v-divider>
    
    <v-stepper-step
      :complete="currentStep > 3"
      step="3"
      class="step-color"
    >
    {{ $vuetify.lang.t('$vuetify.projects.labels.banner.summary') }}
    </v-stepper-step>
    <v-stepper-content v-if="!$vuetify.breakpoint.xs"  step="3"></v-stepper-content>
    <v-divider v-if="$vuetify.breakpoint.xs"></v-divider>
    
  </v-stepper>
  <v-stepper v-if="$vuetify.breakpoint.xs" class="stepper" v-model="currentStep">
    <v-stepper-header>
      <v-stepper-step class="step-color" step="1" :complete="currentStep > 1">
         {{ $vuetify.lang.t('$vuetify.projects.labels.banner.content') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step class="step-color" step="2" :complete="currentStep > 2">
          {{ $vuetify.lang.t('$vuetify.projects.labels.banner.preferences') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step class="step-color" step="3" :complete="currentStep > 3">
          {{ $vuetify.lang.t('$vuetify.projects.labels.banner.summary') }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</div>

</template>

<script>
export default {

  name: 'NewProjectBanner',

  props: {
    currentStep: Number
  }
}
</script>

<style lang="scss" scoped>
.stepper {
  box-shadow: none !important;
  background-color: transparent !important;
  position: sticky;
  top:10%;
}

::v-deep div.step-color.v-stepper__step--active span, ::v-deep div.step-color.v-stepper__step--complete span {
  border: 1px solid #1d8755 !important;
  background-color: transparent !important;
  color: black !important;
  height: 40px;
  width: 40px;
}

::v-deep { 
    div.v-stepper__step.step-color.v-stepper__step--complete i {
    color: #1d8755 !important;
  }
}

::v-deep.step-color.v-stepper__step--inactive span {
  border: 1px solid #979797 !important;
  background-color: transparent !important;
  color: #979797 !important;
  height: 40px;
  width: 40px;
}

::v-deep div.v-stepper__content {
  margin-left: 44px !important;
}

</style>