<template>
  <v-row>
    <v-col class="banner-background" md="3">
      <new-project-banner class="ma-10" :current-step="currentStep" />
    </v-col>
    <v-col md="9">
      <new-project-form :current-step="currentStep" :next="nextStep" :previous-step="previousStep" />
    </v-col>
  </v-row>
</template>

<script>
import NewProjectBanner from '@/modules/projects/components/NewProjectBanner.vue'
import NewProjectForm from '@/modules/projects/components/NewProjectForm.vue'

export default {

  name: 'NewProject',
  components: { NewProjectBanner, NewProjectForm },


  data () {
    return {
      currentStep: 1
    }
  },
  methods: {
    nextStep() {
      this.currentStep += 1
    },
    previousStep() {
      this.currentStep -= 1
    }
  }
}
</script>

<style lang="css" scoped>
.banner-background {
  background-color: #F2F5F8;
}
</style>