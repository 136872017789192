<template>
  <v-sheet width="200">
    <v-sheet :disabled="disabled" elevation="5" height="200" width="200" @click="clickSheet" class="checkButton pointer"
             :class="(disabled? 'disabled':'')">
      <div class="align-content-end pa-3" >
        <v-icon v-if="selected" color="primary">mdi-checkbox-marked</v-icon>
        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
      </div>

      <div class="align-center">
        <v-img :src="image" class="my-auto mx-auto " height="80" width="80" :class="(selected?'':'deselected')" contain>
        </v-img>
      </div>
    </v-sheet>
    <div class="mx-auto text-center mt-5">{{ name }}</div>
  </v-sheet>
</template>

<script>
import image from '@/assets/images/logo_sorcero.svg'

export default {
  name: 'DataSourceCard',
  props: {
    image: {type:String, default:image},
    value: Boolean,
    disabled: Boolean,
    clickable: Boolean,
    name: String
  },
  data() {
    return {
      picture:null,
    }
  },
  methods: {
    clickSheet() {
      if (this.clickable && !this.disabled) {
        this.selected = !this.selected
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value){
        this.$emit('update:value', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: .2;
}
.deselected {
  filter: saturate(0%);
}
.checkButton:hover{
  elevation: higher;
}

::v-deep{
  div.default div.v-input--selection-controls__ripple {
  cursor: default;
} 
}
</style>